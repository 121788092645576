//for authentication
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const PARTNER_REQUEST = "PARTNER_REQUEST";
export const PARTNER_SUCCESS = "PARTNER_SUCCESS";
export const PARTNER_FAILURE = "PARTNER_FAILURE";

export const PARTNER_WALLET_REQUEST = "PARTNER_WALLET_REQUEST";
export const PARTNER_WALLET_SUCCESS = "PARTNER_WALLET_SUCCESS";
export const PARTNER_WALLET_FAILURE = "PARTNER_WALLET_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

// for profile
export const PROFILE_LOAD_REQUEST = "PROFILE_LOAD_REQUEST";
export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_LOAD_SUCCESS = "PROFILE_LOAD_SUCCESS";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_LOAD_FAILURE = "PROFILE_LOAD_FAILURE";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";

// for partner profile
export const PARTNER_PROFILE_UPDATE_SUCCESS = "PARTNER_PROFILE_UPDATE_SUCCESS";

// for blood summay
export const BLOOD_SUMMARY_REQUEST = "BLOOD_SUMMARY_REQUEST";
export const BLOOD_SUMMARY_SUCCESS = "BLOOD_SUMMARY_SUCCESS";
export const BLOOD_SUMMARY_FAILURE = "BLOOD_SUMMARY_FAILURE";
export const BLOOD_SUMMARY_UPDATE_REQUEST = "BLOOD_SUMMARY_UPDATE_REQUEST";
export const BLOOD_SUMMARY_UPDATE_SUCCESS = "BLOOD_SUMMARY_UPDATE_SUCCESS";
export const BLOOD_SUMMARY_UPDATE_FAILURE = "BLOOD_SUMMARY_UPDATE_FAILURE";

// for blood report
export const BLOOD_REPORT_REQUEST = "BLOOD_REPORT_REQUEST";
export const BLOOD_REPORT_SUCCESS = "BLOOD_REPORT_SUCCESS";
export const BLOOD_REPORT_FAILURE = "BLOOD_REPORT_FAILURE";

// for kit registration
export const KIT_REQUEST = "KIT_REQUEST";
export const KIT_SUCCESS = "KIT_SUCCESS";
export const KIT_FAILURE = "KIT_FAILURE";
export const KIT_STEP_SUCCESS = "KIT_STEP_SUCCESS";
export const KIT_STEP_UPDATE = "KIT_STEP_UPDATE";

// for self report
export const SELFTRACK_REQUEST = "SELFTRACK_REQUEST";
export const SELFTRACK_SUCCESS = "SELFTRACK_SUCCESS";
export const SELFTRACK_FAILURE = "SELFTRACK_FAILURE";
export const SELFTRACK_UPDATE_REQUEST = "SELFTRACK_UPDATE_REQUEST";
export const SELFTRACK_UPDATE_SUCCESS = "SELFTRACK_UPDATE_SUCCESS";
export const SELFTRACK_UPDATE_FAILURE = "SELFTRACK_UPDATE_FAILURE";

// stripe products and subscriptions
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const START_SUBSCRIPTION_CHECKOUT_REQUEST = "START_SUBSCRIPTION_CHECKOUT_REQUEST";
export const START_SUBSCRIPTION_CHECKOUT_SUCCESS = "START_SUBSCRIPTION_CHECKOUT_SUCCESS";
export const START_SUBSCRIPTION_CHECKOUT_FAILURE = "START_SUBSCRIPTION_CHECKOUT_FAILURE";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const STRIPE_PORTAL_REQUEST = "STRIPE_PORTAL_REQUEST";
export const STRIPE_PORTAL_SUCCESS = "STRIPE_PORTAL_SUCCESS";
export const STRIPE_PORTAL_FAILURE = "STRIPE_PORTAL_FAILURE";

export const SEND_CHECKOUT_REQUEST = "SEND_CHECKOUT_REQUEST";
export const SEND_CHECKOUT_SUCCESS = "SEND_CHECKOUT_SUCCESS";
export const SEND_CHECKOUT_FAILURE = "SEND_CHECKOUT_FAILURE";

export const UPLOAD_REPORT_REQUEST = "UPLOAD_REPORT_REQUEST";
export const UPLOAD_REPORT_SUCCESS = "UPLOAD_REPORT_SUCCESS";
export const UPLOAD_REPORT_FAILURE = "UPLOAD_REPORT_FAILURE";

// share reports
export const SHARE_REPORT_REQUEST = "SHARE_REPORT_REQUEST";
export const SHARE_REPORT_SUCCESS = "SHARE_REPORT_SUCCESS";
export const SHARE_REPORT_FAILURE = "SHARE_REPORT_FAILURE";

export const UNSHARE_REPORT_REQUEST = "UNSHARE_REPORT_REQUEST";
export const UNSHARE_REPORT_SUCCESS = "UNSHARE_REPORT_SUCCESS";
export const UNSHARE_REPORT_FAILURE = "UNSHARE_REPORT_FAILURE";

export const GET_SHARE_REPORT_REQUEST = "GET_SHARE_REPORT_REQUEST";
export const GET_SHARE_REPORT_SUCCESS = "GET_SHARE_REPORT_SUCCESS";
export const GET_SHARE_REPORT_FAILURE = "GET_SHARE_REPORT_FAILURE";

export const PUT_SHARE_REPORT_REQUEST = "PUT_SHARE_REPORT_REQUEST";
export const PUT_SHARE_REPORT_SUCCESS = "PUT_SHARE_REPORT_SUCCESS";
export const PUT_SHARE_REPORT_FAILURE = "PUT_SHARE_REPORT_FAILURE";

export const GET_SHARE_BY_REPORT_REQUEST = "GET_SHARE_BY_REPORT_REQUEST";
export const GET_SHARE_BY_REPORT_SUCCESS = "GET_SHARE_BY_REPORT_SUCCESS";
export const GET_SHARE_BY_REPORT_FAILURE = "GET_SHARE_BY_REPORT_FAILURE";

export const PUT_SHARE_BY_REPORT_REQUEST = "PUT_SHARE_BY_REPORT_REQUEST";
export const PUT_SHARE_BY_REPORT_SUCCESS = "PUT_SHARE_BY_REPORT_SUCCESS";
export const PUT_SHARE_BY_REPORT_FAILURE = "PUT_SHARE_BY_REPORT_FAILURE";

export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

// orders
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";
