import {
    BLOOD_SUMMARY_REQUEST,
    BLOOD_SUMMARY_SUCCESS,
    BLOOD_SUMMARY_FAILURE,
    BLOOD_SUMMARY_UPDATE_REQUEST,
    BLOOD_SUMMARY_UPDATE_SUCCESS,
    BLOOD_SUMMARY_UPDATE_FAILURE,
    LOGOUT_SUCCESS
} from "../actions/actionTypes";
import { merge } from 'deepmerge';

export default (
    state = {
        isLoading: false,
        loadSuccess: false,
        isUpdating: false,
        summary: [],
        trends: [],
        exceptions: [],
        loadError: null,
        updateError: null,
        updateSuccess: null,
        uid: null
    },
    action
) => {
    switch (action.type) {
        case LOGOUT_SUCCESS: 
            return {
                isLoading: false,
                loadSuccess: false,
                isUpdating: false,
                summary: [],
                trends: [],
                loadError: null,
                updateError: null,
                updateSuccess: false,
            };
        case BLOOD_SUMMARY_REQUEST:
            return {
                ...state,
                isLoading: true,
                loadError: null,
                updateSuccess: false,
                uid: null
            };
        case BLOOD_SUMMARY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loadError: null,
                loadSuccess: true,
                summary: action.summary,
                trends: action.trends,
                exceptions: action.exceptions,
                updateSuccess: false,
                uid: action.uid
            };
        case BLOOD_SUMMARY_FAILURE:
            return {
                ...state,
                isLoading: false,
                isUpdating: false,
                loadSuccess: false,
                loadError: action.error,
                updateSuccess: false,
            };
        case BLOOD_SUMMARY_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
                updateError: null,
                updateSuccess: null,
            };
        case BLOOD_SUMMARY_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                updateError: null,
                updateSuccess: true,
            };
        case BLOOD_SUMMARY_UPDATE_FAILURE:
            return {
                ...state,
                isUpdating: false,
                updateError: action.error,
                updateSuccess: false,
            };
        default:
            return state;
    }
};