import {
    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR
} from "../actions/actionTypes";

export default (
    state = {
        isLoading: false,
        orders: [],
        error: null
    },
    action
) => {
    switch (action.type) {
        case GET_ORDERS_REQUEST: 
            return {
                ...state,
                isLoading: true
            };
        case GET_ORDERS_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                orders: action.orders
            };
        case GET_ORDERS_ERROR: 
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};