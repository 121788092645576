import * as actionTypes from "./actionTypes";
import {
  myFirebase,
  googleAuthProvider,
  facebookAuthProvider
} from "../../firebase/firebase";
import routes from "../../routes";

const requestLogin = () => {
  return {
    type: actionTypes.LOGIN_REQUEST
  };
};

const receiveLogin = (user) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user
  };
};

const loginError = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error: error
  };
};

const requestPartner = () => {
  return {
    type: actionTypes.PARTNER_REQUEST
  };
};

const receivePartner = (partner) => {
  return {
    type: actionTypes.PARTNER_SUCCESS,
    partner
  };
};

const partnerError = (error) => {
  return {
    type: actionTypes.PARTNER_FAILURE,
    error: error
  };
};

const requestResetPassword = () => {
  return {
    type: actionTypes.RESET_PASSWORD_REQUEST
  };
};

const receiveResetPassword = (user) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    user
  };
};

const resetPasswordError = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAILURE,
    error: error
  };
};

const requestLogout = () => {
  return {
    type: actionTypes.LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
};

const logoutError = (error) => {
  return {
    type: actionTypes.LOGOUT_FAILURE,
    error: error
  };
};

const verifyRequest = () => {
  return {
    type: actionTypes.VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: actionTypes.VERIFY_SUCCESS
  };
};

const signUpRequest = () => {
  return {
    type: actionTypes.SIGNUP_REQUEST
  };
};

const signUpSuccess = (user) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS
  };
};

const signUpError = (error) => {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    error: error
  };
};

// auto logout
const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logoutUser());
    }, expirationTime * 1000);
  };
};

export const signUpUser = (email, password, provider) => (dispatch) => {
  dispatch(signUpRequest());

  if (provider === "email") {
    myFirebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        dispatch(signUpSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(signUpError(error.message));
      });
  } else if (provider === "google") {
    myFirebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        dispatch(signUpSuccess());
      })
      .catch(function (error) {
        console.log(error);
        dispatch(signUpError(error.message));
      });
  } else if (provider === "facebook") {
    myFirebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then((result) => {
        dispatch(signUpSuccess());
      })
      .catch(function (error) {
        console.log(error);
        dispatch(signUpError(error.message));
      });
  } else {
    dispatch(loginError("Wrong auth provider for " + provider));
  }
};

export const getPartnerProfile = () => (dispatch) => {
  dispatch(requestPartner());
  let functionRef = myFirebase.functions().httpsCallable("getPartnerProfile");
  functionRef({})
    .then((data) => {
      dispatch(receivePartner(data.data?.data));
    })
    .catch((error) => {
      console.log("Profile err", error);
      dispatch(partnerError(error));
    });
};

export const loginUser = (email, password, provider) => (dispatch) => {
  dispatch(requestLogin());
  if (provider === "email") {
    myFirebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        myFirebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (idTokenResult.claims?.partnerId !== null) {
              dispatch(getPartnerProfile());
              dispatch(receiveLogin(result.user));
              dispatch(checkAuthTimeout(15 * 60));
            } else {
              dispatch(loginError("You do not have permission to login."));
            }
          })
          .catch((err) => {
            dispatch(loginError("You do not have permission to login."));
          });
      })
      .catch((error) => {
        console.log("err", error);
        dispatch(loginError(error.message));
      });
  } else if (provider === "google") {
    myFirebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        dispatch(receiveLogin(result.user));
        dispatch(checkAuthTimeout(15 * 60));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(loginError(error.message));
      });
  } else if (provider === "facebook") {
    myFirebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then((result) => {
        dispatch(receiveLogin(result.user));
        dispatch(checkAuthTimeout(15 * 60));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(loginError(error.message));
      });
  } else {
    dispatch(loginError("Wrong auth provider for " + provider));
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      console.log(error);
      dispatch(logoutError(error.message));
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  myFirebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      myFirebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims?.partnerId !== null) {
            dispatch(getPartnerProfile());
            dispatch(receiveLogin(user));
            dispatch(checkAuthTimeout(15 * 60));
          } else {
            dispatch(loginError("You do not have permission to login."));
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    dispatch(verifySuccess());
  });
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const validateAuthRedirectPath = (path) => (dispatch) => {
  let valid = false;
  let fullPath = "";
  routes.map((prop) => {
    if (prop.collapse) {
      prop.views.map((prop) => {
        if (prop.path === path || prop.layout + prop.path === path) {
          valid = true;
          fullPath = prop.layout + prop.path;
        }
      });
    }
    if (prop.path === path || prop.layout + prop.path === path) {
      valid = true;
      fullPath = prop.layout + prop.path;
    }
  });
  if (valid) {
    dispatch(setAuthRedirectPath(fullPath));
  }
};

export const resetPassword = (email) => (dispatch) => {
  dispatch(requestResetPassword());
  myFirebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      dispatch(receiveResetPassword());
    })
    .catch((error) => {
      dispatch(resetPasswordError(error.message));
    });
};
