import {
    UPLOAD_REPORT_FAILURE,
    UPLOAD_REPORT_SUCCESS,
    UPLOAD_REPORT_REQUEST,
} from "../actions/actionTypes";

export default (
    state = {
        isLoading: false,
        loadSuccess: false,
        loadError: null,
    },
    action
) => {
    switch (action.type) {
        case UPLOAD_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true,
                loadSuccess: false,
                loadError: null,
            };
        case UPLOAD_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loadSuccess: true,
                loadError: null,
            };
        case UPLOAD_REPORT_FAILURE:
            return {
                ...state,
                isLoding: false,
                loadSuccess: false,
                loadError: action.error,
            };
        default:
            return state;
    }
};