import * as actionTypes from "./actionTypes";
import { myFirebase } from "../../firebase/firebase";

const getKitRequest = () => {
    return {
        type: actionTypes.KIT_REQUEST
    };
};

const getKitSuccess = (kit) => {
    return {
        type: actionTypes.KIT_SUCCESS,
        kit: kit
    }
}

const getKitStepSuccess = (data) => {
    return {
        type: actionTypes.KIT_STEP_SUCCESS,
    }
}

const saveKitInfo = (data) => {
    return {
        type: actionTypes.KIT_STEP_UPDATE,
        kit: data
    }
}

const getKitFailure = (error) => {
    return {
        type: actionTypes.KIT_FAILURE,
        error: error
    }
}

export const listRegisterdUpc = () => dispatch => {
    dispatch(getKitRequest());
    const functionRef = myFirebase.functions('us-central1').httpsCallable('listRegisterdUpc');
    functionRef({}).then(
        (data) => {
            if(data.data.status === "succeed"){
                dispatch(getKitSuccess(data.data?.data))
            }
        }
    ).catch(error => {
        dispatch(getKitFailure(error.message))
    });    
}

export const getUpcCode = (upc) => dispatch => {
    dispatch(getKitRequest());
    const functionRef = myFirebase.functions('us-central1').httpsCallable('getUpcCode');
    functionRef({
        upc: upc
    }).then(
        (data) => {
            dispatch(getKitStepSuccess())
            dispatch(saveKitInfo(data.data))
        }
    ).catch(error => {
        dispatch(getKitFailure(error.message))
    });    
}

export const registerUpcCode = (email, first_name, last_name, gender, date_of_birth, upc) => dispatch => {
    dispatch(getKitRequest());
    const functionRef = myFirebase.functions('us-central1').httpsCallable('registerUpcCode');
    functionRef({
        email: email,
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        date_of_birth: date_of_birth,
        upc: upc
    }).then(
        (data) => {
            dispatch(getKitStepSuccess())
        }
    ).catch(error => {
        dispatch(getKitFailure(error.message))
    });    
}
