import * as actionTypes from "./actionTypes";
import { myFirebase } from "../../firebase/firebase";

const requestOrders = () => {
    return {
        type: actionTypes.GET_ORDERS_REQUEST
    };
};

const receiveOrders = orders => {
    return {
        type: actionTypes.GET_ORDERS_SUCCESS,
        orders
    };
};

const ordersLoadError = error => {
    return {
        type: actionTypes.GET_ORDERS_ERROR,
        error
    };
};

export const getPartnerOrders = () => dispatch => {
    dispatch(requestOrders());

    const functionRef = myFirebase.functions('us-central1').httpsCallable('listOrdersForPartner');
    functionRef({
        includeAll: false
    }).then(
        (response) => {
            dispatch(receiveOrders(response.data.data));
        }
    ).catch(error => {
        console.log(error);
        dispatch(ordersLoadError(error.message));
    });
}
