import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/storage";
import { loadStripe } from "@stripe/stripe-js";

const firebaseConfigBeta = {
  apiKey: "AIzaSyDZaR1Uo7WQTDojlFClLH4idAlSBAaEbV8",
  authDomain: "labme-beta.firebaseapp.com",
  databaseURL: "https://labme-beta.firebaseio.com",
  projectId: "labme-beta",
  storageBucket: "labme-beta.appspot.com",
  messagingSenderId: "440048496206",
  appId: "1:440048496206:web:af3dfd4666772dc79a1d1f",
  measurementId: "G-EPEMBPT7QS"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDvajHar3Bv6rb-RMS_2OEr5tUmktn-pWQ",
  authDomain: "labme-prod.firebaseapp.com",
  databaseURL: "https://labme-prod.firebaseio.com",
  projectId: "labme-prod",
  storageBucket: "labme-prod.appspot.com",
  messagingSenderId: "1065219065302",
  appId: "1:1065219065302:web:05baa2bdac4520d5759077",
  measurementId: "G-Y65E4H82DT"
};

let firebaseConfig = null;
if (process.env.REACT_APP_STAGE === "prod") {
  firebaseConfig = firebaseConfigProd;
} else {
  firebaseConfig = firebaseConfigBeta;
}

export const myFirebase = firebase.initializeApp(firebaseConfig);

// initialize analytics
const analytics = myFirebase.analytics();
export const firebaseAnalytics = analytics;

const eventName = firebase.analytics.EventName;
export const firebaseAnalyticsEventName = eventName;

const baseDb = myFirebase.firestore();
export const db = baseDb;
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const stripeTestPublicKey = "pk_test_tlok03u8Pv3bshQte6x9VXXC";
const stripeProdPublicKey = "pk_live_OgEqz1YLNI1A5VgoJcHwuP5x";

let stripePublickKey = null;
if (process.env.REACT_APP_STAGE === "prod") {
  stripePublickKey = stripeProdPublicKey;
} else {
  stripePublickKey = stripeTestPublicKey;
}

let stripeRedirectUrl_ = null;
if (process.env.REACT_APP_STAGE === 'prod') {
  stripeRedirectUrl_ = "https://partner.labme.ai";
} else {
  stripeRedirectUrl_ = "https://partner-beta.firebaseapp.com";
}

let googlePlacesApi_ = null;
if (process.env.REACT_APP_STAGE === "prod") {
  googlePlacesApi_ = "AIzaSyDvajHar3Bv6rb-RMS_2OEr5tUmktn-pWQ";
} else {
  googlePlacesApi_ = "AIzaSyDZaR1Uo7WQTDojlFClLH4idAlSBAaEbV8";
}

export const googlePlacesApi = googlePlacesApi_;
export const stripeRedirectUrl = stripeRedirectUrl_;

export const stripePromise = loadStripe(stripePublickKey);

// initialize storage
export const storage = firebase.storage();
export const storageObject = firebase.storage;
