import * as actionTypes from "./actionTypes";
import { myFirebase } from "../../firebase/firebase";
import firebase from 'firebase/app'

const requestSelftrack = () => {
    return {
        type: actionTypes.SELFTRACK_REQUEST
    };
};

const receiveSelftrack = selftrack => {
    const medications = Object.values(selftrack.medications);
    const data = {...selftrack, medications};
    return {
        type: actionTypes.SELFTRACK_SUCCESS,
        selftrack: data
    };
};

const selftrackError = error => {
    return {
        type: actionTypes.SELFTRACK_UPDATE_FAILURE,
        error: error
    }
}

const requestSelftrackUpdate = () => {
    return {
        type: actionTypes.SELFTRACK_UPDATE_REQUEST,
    }
}

const receiveSelftrackUpdate = (path, update, ops) => {
    return {
        type: actionTypes.SELFTRACK_UPDATE_SUCCESS,
        update: update,
        path: path,
        ops: ops
    }
}

const selftrackUpdateError = error => {
    return {
        type: actionTypes.SELFTRACK_UPDATE_FAILURE,
        error: error
    }
}

export const getSefltracking = (id) => dispatch => {
    dispatch(requestSelftrack());
    myFirebase.firestore().collection('selftrack').doc(id).get().then(doc => {
        if (doc.exists) {
            dispatch(receiveSelftrack(doc.data()));
        } else {
            dispatch(receiveSelftrack({
                medications: {},
                current_diagnosis: [],
                past_diagnosis: [],
                lifestyle: [],
                blood_pressure: [],
                height: '',
                weight: [],
                glucose_meter: []
            }));
            myFirebase.firestore().collection('selftrack').doc(id).set({
                medications: {},
                current_diagnosis: [],
                past_diagnosis: [],
                lifestyle: [],
                blood_pressure: [],
                height: '',
                weight: [],
                glucose_meter: []
            }, { merge: true }).then(doc => {
                console.log('Created the selftrack doc');
            }).catch(error => {
                console.log(error)
            });
        }
    }).catch(error => {
        console.log(error.message);
        dispatch(selftrackError(error.message));
    })
}

export const addMedication = (id, path, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    const data_id = new Date().getTime();
    const new_path = path + '.' + data_id.toString();
    const new_data = {id: data_id, ...data};
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [new_path]: new_data
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, new_data, 'add'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}

export const updateMedication = (id, path, data_id, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    const new_path = path + '.' + data_id;
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [new_path]: data
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, data, 'update'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}

export const removeMedication = (id, path, data_id, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    const new_path = path + '.' + data_id;
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [new_path]: firebase.firestore.FieldValue.delete()
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, data, 'remove'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}

export const addSelftracking = (id, path, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [path]: firebase.firestore.FieldValue.arrayUnion(data)
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, data, 'add'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}

// This will reset the data
export const updateSelftracking = (id, path, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [path]: data
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, data, 'update'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}

export const removeSelftracking = (id, path, data) => dispatch => {
    dispatch(requestSelftrackUpdate());
    myFirebase.firestore().collection('selftrack').doc(id).update({
        [path]: firebase.firestore.FieldValue.arrayRemove(data)
    }).then(doc => {
        dispatch(receiveSelftrackUpdate(path, data, 'remove'));
    }).catch(error => {
        console.log(error);
        dispatch(selftrackUpdateError(error.message));
    })
}
