import Login from "views/pages/auth/Login.js";
import ResetPassword from "views/pages/auth/ResetPassword.js";
import Register from "views/pages/auth/Register.js";

import React from 'react';
const Products = React.lazy(() => import('views/pages/products/Products.js'));
const Profile = React.lazy(() => import('views/pages/profile/Profile.js'));
const Orders = React.lazy(() => import('views/pages/orders/Order.js'));
const BloodTestReport = React.lazy(() => import('views/pages/bloodtracking/BloodTestReport.js'));
const KitRegistration = React.lazy(() => import('views/pages/kit/KitRegistration.js'));
const MainDashboard = React.lazy(() => import('views/pages/dashboard/MainDashboard.js'));
//const BloodVisulization = React.lazy(() => import('views/pages/bloodtracking/BloodVisulization'));
const Medications = React.lazy(() => import("views/pages/selftracking/medication/Medications"));
const Dailyactivity = React.lazy(() => import("views/pages/selftracking/dailyactivity/Dailyactivity"));
const AnalyDash = React.lazy(() => import("layouts/AnalyDash"));
const BloodReport = React.lazy(() => import('views/pages/bloodreport/BloodReport.js'));
const Subscription = React.lazy(() => import('views/pages/subscription/Subscription.js'));
const Checkout = React.lazy(() => import('views/pages/checkout/Checkout.js'));

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-cubes text-white",
    component: MainDashboard,
    layout: "/dashboard"
  },
  {
    path: "/products",
    name: "Products",
    icon: "fa fa-box text-white",
    component: Products,
    layout: "/dashboard"
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "fa fa-chart-line text-white",
    component: Orders,
    layout: "/dashboard"
  },
  {
    path: "/profile",
    name: "Profiles",
    icon: "fa fa-user-alt text-white",
    component: Profile,
    layout: "/dashboard"
  },
  {
    path: "/checkout",
    name: "Checkout",
    icon: null,
    component: Checkout,
    layout: "/website"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-circle-08 text-white",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-white",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    icon: "ni ni-circle-08 text-white",
    component: ResetPassword,
    layout: "/auth"
  }
];

export default routes;
