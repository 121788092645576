import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Row,
    Col,
    CardTitle,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import ReactPixel from 'react-facebook-pixel';
import {pixelId} from "../../../firebase/config";
import { firebaseAnalytics } from "../../../firebase/firebase";

ReactPixel.init(pixelId);

class CheckoutFail extends React.Component {

    componentDidMount() {
        ReactPixel.trackCustom("paymentFail", {});
        firebaseAnalytics.logEvent("paymentFail",  {});
    }

    render() {
        return (
            <>
                <IndexNavbar />
                <div className="content py-6 pb-9">
                    <Container className="mt--4 text-center" fluid>
                        <Row className="card-wrapper">
                            <Col sm="12" md={{ size: 4, offset: 4 }}>
                                <Card >
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/paymentfail.png")}
                                        center
                                    />
                                    <CardBody>
                                        <CardTitle className="mb-3" tag="h3">
                                            Sorry, your payment failed, please make sure that you have active balance in your account.
                                    </CardTitle>
                                        <Button
                                            color="primary"
                                            href="/dashboard/products"
                                        >
                                            Go To Product Page
                                </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <AuthFooter />
            </>
        );
    }
}


export default CheckoutFail;
