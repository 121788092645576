import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Row,
    Col,
    CardTitle,
} from "reactstrap";
import * as actions from "../../../store/actions/index";
import { connect } from "react-redux";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import {pixelId} from "../../../firebase/config";
import ReactPixel from 'react-facebook-pixel';
import {
    firebaseAnalytics,
    firebaseAnalyticsEventName
  } from "../../../firebase/firebase";

ReactPixel.init(pixelId);

class CheckoutSuccess extends React.Component {
    componentDidMount() {
        if (!this.props.loadSuccess) {
            this.props.getProductList();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            this.props.productDetails &&
            this.props.productDetails !== prevProps.productDetails
          ) {
            const { location } = this.props;
            const query = new URLSearchParams(location.search);
            const productId = query.get('product');
            const frequency = query.get('frequency');
            const source = query.get('source');
            const product = this.props.productDetails[productId];
            const productPrice = product?.prices[frequency]?.price.substring(1);
            ReactPixel.track("Purchase", {
                content_ids: [productId],
                content_name: product?.name,
                num_items: 1,
                value: parseFloat(productPrice),
                currency: "USD",
                contents: [
                  {
                    id: productId,
                    quantity: 1
                  }
                ],
                content_type: "product",
                source: source
              });
               firebaseAnalytics.logEvent(firebaseAnalyticsEventName.PURCHASE, {
                transaction_id: 1,
                value: parseFloat(productPrice),
                currency: "USD",
                tax: "0.0",
                items: [
                  {
                    item_name: product?.name,
                    item_id: productId,
                    price: parseFloat(productPrice),
                    item_brand: "LabMe",
                    item_category: "None",
                    quantity: 1
                  }
                ],
                source: source
              });
        }
      }
    
    render() {
        return (
            <>
                <IndexNavbar />
                <div className="content py-6 pb-9">
                    <Container className="mt--4 text-center" fluid>
                        <Row className="card-wrapper">
                            <Col sm="12" md={{ size: 4, offset: 4 }}>
                                <Card >
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/paymentsuccess.png")}
                                        center
                                    />
                                    <CardBody>
                                        <CardTitle className="mb-3" tag="h3">
                                            You will receive an email for your tracking number when test kit gets shipped.
                                        </CardTitle>
                                        <Button
                                            color="primary"
                                            href="/dashboard/dashboard"
                                        >
                                            Go To Dashboard
                                </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <AuthFooter />
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
      productDetails: state.stripe.products,
      loadSuccess: state.stripe.loadSuccess
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getProductList: () => dispatch(actions.getProductList())
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);

