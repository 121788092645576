/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer p-2 mb-2 px-3">

            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1 name"
                    href="https://www.labme.ai"
                    target="_blank"
                  >
                    Lab Me Analytics
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink
                      href="https://www.labme.ai/about-lab-me/"
                      target="_blank"
                    >
                    About Us
                    </NavLink> 
                  </NavItem>
                  <div className="nav-footer-divider">|</div>
                  <NavItem>
                    <NavLink
                      href="https://www.labme.ai/privacy-policy/"
                      target="_blank"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                  <div className="nav-footer-divider">|</div>
                  <NavItem>
                    <NavLink
                      href="https://www.labme.ai/terms-conditions/"
                      target="_blank"
                    >
                      Terms & Conditions
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
