import moment from 'moment';

export const isDateValid = (date) => {
  const mmValue = moment(date, 'YYYY-M-D', true);

  if (!mmValue.isValid()) {
    return false;
  }

  if (mmValue.year() < 1900) {
    return false;
  }

  return true;
}

export const formatDate = (date) => {
  if (!isDateValid(date)) {
    throw new Error(`Invalid date ${date} is found`);
  }

  const mmValue = moment(date, 'YYYY-M-D');

  return mmValue.format('MM/DD/YYYY');
}

export const trimString = (obj) => {
  if (typeof obj === 'string') {
    return obj.trim();
  }

  return obj;
} 