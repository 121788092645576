import {
    BLOOD_REPORT_REQUEST,
    BLOOD_REPORT_SUCCESS,
    BLOOD_REPORT_FAILURE,
    LOGOUT_SUCCESS
} from "../actions/actionTypes";

export default (
    state = {
        isLoading: false,
        reports: {},
        loadError: null,
    },
    action
) => {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return {
                isLoading: false,
                reports: {},
                loadError: null,
            };
        case BLOOD_REPORT_REQUEST:
            return {
                ...state,
                isLoding: true,
                loadError: null
            };
        case BLOOD_REPORT_SUCCESS:
            if (!action.report) {
                return {
                    ...state,
                    isLoding: false,
                    loadError: null,
                };
            }

            const report = {...state.reports};
            report[action.raw_data.specimen_id] = { raw_data: action.raw_data, report: action.report };
            return {
                ...state,
                isLoding: false,
                loadError: null,
                reports: report
            };
        case BLOOD_REPORT_FAILURE:
            return {
                ...state,
                isLoding: false,
                isUpdating: false,
                loadError: action.error,
            };
        default:
            return state;
    }
};